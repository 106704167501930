
import 'loaders.css/loaders.min.css';

import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		redirectText: {
			type: String,
			required: true,
		},
	},
});
